import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {config} from '../Constant';
import {Alert, Button, Snackbar} from '@mui/material';
import PetInfoCard from '../components/PetInfoCard';
import PetInfoCardSkeleton from '../components/PetInfoCardSkeleton';
import ContactInfoCard from '../components/ContactInfoCard';
import ContactInfoCardSkeleton from '../components/ContactInfoCardSkeleton';
import NoTagPage from './NoTag';
import {useTranslation} from "react-i18next";
import PetPicturesCaroussel from "../components/PetPicturesCaroussel";
import PetExtraInfoCard from "../components/PetExtraInfoCard";
import MarengoCopyright from "../components/MarengoCopyright";
import FaqCard from "../components/FaqCard";

const API_URL = config.url.API_URL;

export interface ITagPageProps {
}

const TagPage: React.FunctionComponent<ITagPageProps> = () => {
    let {pathname} = useLocation();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    let [petName, setPetName] = useState<string>('');
    let [petId, setPetId] = useState<number>(0);
    let [petSex, setPetSex] = useState<string>('');
    let [petDateOfBirth, setPetDateOfBirth] = useState<Date>(new Date());
    let [petSpecies, setPetSpecies] = useState<string>('');
    let [petWeightInPounds, setPetWeightInPounds] = useState<number>(0);
    let [petDescription, setPetDescription] = useState<string>('');
    let [petIsOutdoor, setPetIsOutdoor] = useState<boolean>(false);
    let [petIsLost, setPetIsLost] = useState<boolean>(false);
    let [hasSentScan, setHasSentScan] = useState<boolean>(false);
    let [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    let [localisationErrorSnackBar, setLocalisationErrorSnackBar] = useState<boolean>(false);

    let [contactList, setContactList] = useState<any[]>([]);
    let [noTag, setNoTag] = useState<boolean>(false);

    let [petPicturesList, setPetPicturesList] = useState<string[]>([]);
    let tagCode = pathname.substring(pathname.lastIndexOf('/') + 1);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbar1MinMessage, setOpenSnackbar1MinMessage] = useState(false);


    useEffect(() => {
        fetchContactInfo();
        fetchPetInfo();
    }, []);

    useEffect(() => {
        let hasSentScan = localStorage.getItem(`hasSentScan${searchParams.get('tabId')}`);
        if (hasSentScan == null) {
            if (petId !== 0) {
                sendScanInfo();
            }
        }
    }, [petId])

    const fetchContactInfo = () => {
        axios.get(`${API_URL}getContactInfoFromTagCode/${tagCode}`)
            .then(function (response) {
                if (response.status === 200) {
                    setContactList(response.data.owners)
                } else {
                    setNoTag(true)
                }
            })
            .catch(function (error) {
                setNoTag(true)
            })
    };

    const fetchPetInfo = () => {
        axios.get(`${API_URL}getPetInfoFromTagCode/${tagCode}`)
            .then(function (response) {
                setPetName(response.data.pet.Name);
                setPetSex(response.data.pet.Sex);
                setPetSpecies(response.data.pet.Species);
                setPetWeightInPounds(response.data.pet.Weight * 2.205);
                setPetDescription(response.data.pet.Notes);
                setPetIsOutdoor(response.data.pet.isOutdoor);
                setPetIsLost(response.data.pet.isLost);

                setPetPicturesList(response.data.petPicturesUrl);
                setPetDateOfBirth(new Date(response.data.pet.DOB));
                setPetId(response.data.pet.ID);
            })
            .catch(function (error) {
            });
    };

    const sendScanInfo = () => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({name: "geolocation"})
                .then(function (result) {
                    if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(checkForSendingScan, locationPermissionsErrors, options);
                    }
                    if (result.state === "prompt") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(checkForSendingScan, locationPermissionsErrors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                        setLocalisationErrorSnackBar(true);
                        checkForSendingScan({'coords': ''});
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    function checkForSendingScan(pos: any) {
        if (hasSentScan || petId === 0 || isWaitingForResponse) {
            return;
        }
        sendTagScan(pos);
    }

    function sendTagScan(pos: any) {
        const crd = pos.coords;

        setIsWaitingForResponse(true);

        const body = {
            latitude: crd.latitude,
            longitude: crd.longitude,
            pet_id: `${petId}`,
            scanned_date: new Date().toUTCString(),
            accuracy: crd.accuracy
            // Add any other data you want to send
        };

        axios.post(`${API_URL}createTagScan/`, body)
            .then(function (response) {
                if (response.status === 201) {
                    setOpenSnackbar(true);
                    setHasSentScan(true);
                    setIsWaitingForResponse(false);
                } else if (response.status === 429) {
                    setOpenSnackbar1MinMessage(true);
                }
                localStorage.setItem(`hasSentScan${searchParams.get('tabId')}`, 'true');
            })
            .catch(function (error) {
                if (error.response.status === 429) {
                    setOpenSnackbar1MinMessage(true);
                }
                localStorage.setItem(`hasSentScan${searchParams.get('tabId')}`, 'true');
                setIsWaitingForResponse(false);
            })
    }

    function locationPermissionsErrors(err: any) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        setLocalisationErrorSnackBar(true);
        checkForSendingScan({'coords': ''});
    }

    function alertButton() {
        if (localisationErrorSnackBar) {
            setLocalisationErrorSnackBar(true);
        } else {
            navigator.geolocation.getCurrentPosition(sendTagScan, locationPermissionsErrors, options);
        }
    }

    return (
        <div>
            {noTag ? (
                <NoTagPage></NoTagPage>
            ) : (
                <div style={{paddingBottom: '2rem'}}>
                    <PetPicturesCaroussel petIsLost={petIsLost} petPicturesList={petPicturesList}/>
                    {
                        petName ? (
                            <PetInfoCard petName={petName}
                                         petSpecies={petSpecies}
                                         petDescription={petDescription}
                                         petIsOutdoor={petIsOutdoor}
                                         petIsLost={petIsLost}/>
                        ) : (
                            <PetInfoCardSkeleton/>
                        )
                    }
                    {petIsLost ? (<Button
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={alertButton}
                        style={{
                            marginRight: '1rem',
                            marginLeft: '1rem',
                            marginTop: '1rem',
                            padding: '1rem',
                            borderRadius: '20px',
                            position: 'relative',
                            width: '90%',
                        }}
                    >
                        {t("alert_owner")}
                    </Button>) : <div/>}
                    {
                        contactList && contactList.length > 0 ? (
                            <ContactInfoCard contactList={contactList}/>
                        ) : (
                            <ContactInfoCardSkeleton/>
                        )
                    }
                    {
                        petName ? (
                            <PetExtraInfoCard petDateOfBirth={petDateOfBirth}
                                              petSex={petSex}
                                              petWeightInPounds={petWeightInPounds}/>
                        ) : (
                            <PetInfoCardSkeleton/>
                        )
                    }
                    {!petIsLost ? (<Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={alertButton}
                        style={{
                            marginRight: '1rem',
                            marginLeft: '1rem',
                            marginTop: '1rem',
                            padding: '1rem',
                            borderRadius: '20px',
                            position: 'relative',
                            width: '90%',
                        }}
                    >
                        {t("alert_owner")}
                    </Button>) : <div/>}
                    <br/>
                    <br/>
                    <FaqCard></FaqCard>
                    {hasSentScan ? (
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000} // Adjust the duration as needed
                            onClose={() => setOpenSnackbar(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }} // Center align Snackbar horizontally
                            sx={{
                                '& .MuiAlert-root': {
                                    width: '100%',
                                    fontSize: '1.3rem', // Adjust the font size as needed
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={() => setOpenSnackbar(false)}
                                severity="success" // Change the severity as needed (success, error, warning, info)
                            >
                                {t("user_has_been_alerted")}
                            </Alert>
                        </Snackbar>) : <div/>
                    }
                    {localisationErrorSnackBar ? (
                        <Snackbar
                            open={localisationErrorSnackBar}
                            autoHideDuration={3000} // Adjust the duration as needed
                            onClose={() => setLocalisationErrorSnackBar(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }} // Center align Snackbar horizontally
                            sx={{
                                '& .MuiAlert-root': {
                                    width: '100%',
                                    fontSize: '1.3rem', // Adjust the font size as needed
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={() => setLocalisationErrorSnackBar(false)}
                                severity="error" // Change the severity as needed (success, error, warning, info)
                            >
                                {t("location_not_shared")}
                            </Alert>
                        </Snackbar>) : <div/>
                    }
                    {openSnackbar1MinMessage ? (
                        <Snackbar
                            open={openSnackbar1MinMessage}
                            autoHideDuration={3000} // Adjust the duration as needed
                            onClose={() => setOpenSnackbar1MinMessage(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }} // Center align Snackbar horizontally
                            sx={{
                                '& .MuiAlert-root': {
                                    width: '100%',
                                    fontSize: '1.3rem', // Adjust the font size as needed
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={() => setOpenSnackbar1MinMessage(false)}
                                severity="error" // Change the severity as needed (success, error, warning, info)
                            >
                                {t("1min_snackbar_message")}
                            </Alert>
                        </Snackbar>) : <div/>
                    }
                </div>)}
            <MarengoCopyright/>
        </div>
    );
};

export default TagPage;