import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Accordion, AccordionDetails, AccordionSummary, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface FaqCardState {
}

const FaqCard: React.FunctionComponent<FaqCardState> = () => {
    const {t} = useTranslation();

    return (
        <Paper elevation={4} style={{
            marginRight: '1rem',
            marginLeft: '1rem',
            marginBottom: '1rem',
            borderRadius: '20px',
        }}>
            <Accordion
                elevation={0}
                style={{
                    borderRadius: '20px',
                }}
            >
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown}/>}>
                    <Typography variant="h6">{t('faq.title')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{paddingTop: '0rem'}}>
                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} gutterBottom>
                        {t('faq.location_permissions_title')}
                    </Typography>
                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1rem', color: "#8B8B8B" }} gutterBottom>
                        {t('faq.location_permissions_description')}
                    </Typography>

                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} gutterBottom>
                        {t('faq.phone_notifications_title')}
                    </Typography>
                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1rem', color: "#8B8B8B" }} gutterBottom>
                        {t('faq.phone_notifications_description')}
                    </Typography>

                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} gutterBottom>
                        {t('faq.scan_timing_title')}
                    </Typography>
                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1rem', color: "#8B8B8B" }} gutterBottom>
                        {t('faq.scan_timing_description')}
                    </Typography>

                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} gutterBottom>
                        {t('faq.tag_scan_title')}
                    </Typography>
                    <Typography style={{ textAlign: 'left' }} sx={{ fontSize: '1rem', color: "#8B8B8B" }} gutterBottom>
                        {t('faq.tag_scan_description')}
                    </Typography>
                    <Typography style={{ textAlign: 'left' }} gutterBottom>
                        {t('for_more_info')}
                        <a href="https://marengoapp.ca/help-center/" style={{ color: '#1E90FF' }}>
                            marengoapp.ca
                        </a>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}


export default FaqCard;
