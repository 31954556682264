import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';

const LanguageDropdown: React.FC = () => {
    const { i18n } = useTranslation();
    const {t} = useTranslation();

    const changeLanguage = (event: SelectChangeEvent): void => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage).then();
    };

    return (
        <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <Select
                labelId="language-select-label"
                value={i18n.language}
                onChange={changeLanguage}
            >
                <MenuItem value="en">{t('english')}</MenuItem>
                <MenuItem value="fr">{t('french')}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageDropdown;
