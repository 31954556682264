import {Grid, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import LanguageDropdown from "./LanguageDropdown";

export interface MarengoCopyrightProps {
}

const MarengoCopyright: React.FunctionComponent<MarengoCopyrightProps> = () => {
    const {t} = useTranslation();

    return (
        <div>
            <Grid
                container
                sx={{
                    marginTop: '2rem',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: '#FFFFFF',
                    padding: '1rem',
                }}
            >
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Typography variant="body2" color="textSecondary">
                        &copy; {new Date().getFullYear()} Marengo Inc. {t('all_right_reserved')}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LanguageDropdown />
                </Grid>
            </Grid>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default MarengoCopyright;