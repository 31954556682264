import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardContent, CardHeader, Paper, Typography} from '@mui/material';
import React from 'react';

interface VetInfoMicrochipState {
    vetFirstName: string;
    vetName: string;
    vetEmail: string;
    vetPhoneNumber: string;
}

const VetInfoCardMicrochip: React.FunctionComponent<VetInfoMicrochipState> = (props) => {
    return (
        <Paper elevation={5} style={{
            margin: '1rem',
            borderRadius: '20px',
            position: 'relative',
        }}>
            <Card variant="outlined">
                <CardHeader
                    title={<Typography sx={{fontSize: '2rem', fontWeight: 'bold'}}>
                        {props.vetFirstName + " " + props.vetName}
                    </Typography>}
                    style={{textAlign: 'left', paddingBottom: '0rem'}}
                />
                <CardContent style={{paddingTop: '0rem'}}>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        <FontAwesomeIcon
                            icon={faEnvelope} size="xl"/>
                        <span style={{fontWeight: 'normal', color: "#8B8B8B"}}>
                            {"\t" + props.vetEmail}
                        </span>
                    </Typography>
                    <Typography style={{textAlign: 'left'}} sx={{fontSize: '1.2rem', fontWeight: 'bold'}} gutterBottom>
                        <FontAwesomeIcon
                            icon={faPhone} size="xl"/>
                        <span style={{fontWeight: 'normal', color: "#8B8B8B"}}>
                            {"\t" + props.vetPhoneNumber}
                        </span>
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    )
}


export default VetInfoCardMicrochip;
