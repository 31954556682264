import {Button, Grid, MobileStepper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from "axios";
import {config} from "../Constant";
import LostPetCard from "./LostPetCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

const API_URL = config.url.API_URL;

export interface LostPet {
    id: number;
    name: string;
    species: string;
    breed: string;
    sex: string;
    dob: Date;
    notes: string;
    mainPetPictureDownloadUrl: any;
    lastSeen: any;
}

export interface LostPetListProps {
}

const LostPetList: React.FunctionComponent<LostPetListProps> = () => {
    const {t} = useTranslation();
    let [petList, setPetList] = useState<LostPet[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const petsPerPage = 6;

    // Fetch pet list for the current page
    const fetchLostPetList = (page: number) => {
        axios.post(`${API_URL}fetchLostPetsPublic/`, { limit: petsPerPage, page: page + 1 })
            .then((response) => {
                if (response.status === 200) {
                    const mappedPetList = response.data.lostPets.map((pet: any) => ({
                        id: pet.id,
                        name: pet.Name,
                        species: pet.Species,
                        breed: pet.Breed,
                        sex: pet.Sex,
                        dob: new Date(pet.DOB),
                        notes: pet.Notes,
                        mainPetPictureDownloadUrl: pet.mainPetPictureDownloadUrl,
                        lastSeen: pet.last_seen,
                    }));
                    setPetList(mappedPetList);
                }
            })
            .catch((error) => {
                console.error("Failed to fetch pet list:", error);
            });
    };

    useEffect(() => {
        fetchLostPetList(currentPage);
    }, [currentPage]);

    // Handle stepper navigation
    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleBack = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    return (
        <div>
            <Typography variant='h4' color={"#5b5b5b"} fontWeight={"bold"}>{t("lost_pet_list")}</Typography>
            <br/>
            <Grid container spacing={2}>
                {petList.map((pet, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index} >
                        <LostPetCard pet={pet}/>
                    </Grid>
                ))}
            </Grid>
            <br/>
            <br/>
            <br/>
            <MobileStepper
                steps={0} // Set to -1 to remove the exact step count if it's unknown
                position="static"
                activeStep={currentPage}
                nextButton={
                    <Button onClick={handleNext} disabled={petList.length < petsPerPage}>
                        <FontAwesomeIcon icon={faCircleArrowRight} fontSize={"2rem"}/>
                    </Button>
                }
                backButton={
                    <Button onClick={handleBack} disabled={currentPage === 0}>
                        <FontAwesomeIcon icon={faCircleArrowLeft} fontSize={"2rem"}/>
                    </Button>
                }
                sx={{
                    marginTop: '-75px',
                    position: 'relative',
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    '.MuiMobileStepper-dot': {
                        backgroundColor: 'white',
                        margin: '0 4px',
                        width: '13px',
                        height: '13px',
                    },
                    '.MuiMobileStepper-dotActive': {
                        backgroundColor: "#404EED",
                        margin: '0 4px',
                        width: '13px',
                        height: '13px',
                    },
                    justifyContent: 'center',
                }}
            />
        </div>
    );
}


export default LostPetList;
